// huFob3 als Single Page Application
// Created in April 2020 by Maximillian Dornseif
// Copyright Cyberlogi 2020
// Copyright HUDORA 2021, 2024

import "./PageFrame.css";

import { useAuth0 } from "@auth0/auth0-react";
import { FontIcon } from "@fluentui/react/lib/Icon"; // HdAirplaneIcon
import { useFeatures } from "flagged";
import React from "react";
import { Link, matchRoute, useLocation, useRoute, useRouter } from "wouter";

import { VersionChecker } from "./VersionChecker";
import { HdAirplaneIcon } from "@hudora/hd-react-simple-components";

export function PageFrame(props: { children?: any }) {
  return (
    <div>
      <Header />
      <main className="hd-main-content">
        <VersionChecker />
        {props.children}
      </main>
    </div>
  );
}

function Header() {
  const features = useFeatures();
  const { isAuthenticated, logout } = useAuth0();

  const items = [
    { url: "/wawi", title: "Warenwirtschaft", icon: "Packages", hotkey: "w" },
    { url: "/belege", title: "Belege", icon: "Document", hotkey: "b" },
    { url: "/ic", title: "Lager", icon: "ProductVariant", hotkey: "l" },
    { url: "/stamm", title: "Stammdaten", icon: "MasterDatabase", hotkey: "s" },
    // { url: '/fob', title: 'Fob', icon: 'Globe', hotkey: 'f' },
    // { url: '/einkauf', title: 'Einkauf', icon: 'Ferry', hotkey: 'k' },
    { url: "/doc", title: "Dokumentation", icon: "BookAnswers", hotkey: "d" },
  ];

  return (
    <header className="hd-nav">
      <span
        dangerouslySetInnerHTML={{
          __html: `<!-- ${JSON.stringify(features)} -->`,
        }}
      />
      <nav className="hd-navbar">
        <div className="hd-navbar">
          <section className="hd-align-left">
            <div className="hd-heading">
              <Link href={`/dev/`}>
                <HdAirplaneIcon /> TEST
              </Link>
              <Link href={`/`}>{process.env.REACT_APP_NAME}</Link>
            </div>

            <section className="hd-divider"></section>

            {items.map((x) =>
              features[x.url.replace(/\//g, "")] ? (
                <MyButtonLink key={x.url} href={x.url} title={x.title} hotkey={x.hotkey}>
                  {x.icon ? <FontIcon aria-label={x.icon} iconName={x.icon} /> : null}
                  <span>{x.title}</span>
                </MyButtonLink>
              ) : (
                <span
                  key={x.url}
                  dangerouslySetInnerHTML={{
                    __html: `<!-- ${x.url.replace(/\//g, "")} -->`,
                  }}
                />
              ),
            )}

            {/* <a href="https://reporting3.hudora.de/" title="Reporting">
              <button title="Reporting">
                <FontIcon aria-label={'ReportDocument'} iconName={'ReportDocument'} />
                <span>Reporting</span>
              </button>
            </a>
            <a href="https://eap3.hudora.de/" title="eAP">
              <button title="eAP">
                <FontIcon aria-label={'ProductList'} iconName={'ProductList'} />
                <span>eAP</span>
              </button>
            </a> */}

            <section className="hd-divider"></section>

            <div id="hd-search-widget-root" />
          </section>

          <section className="hd-align-right">
            <Link href="/doc/c/frontend/">
              <button type="button">{process.env.REACT_APP_VERSION}</button>
            </Link>

            <span id="hd-user-info-root" />

            {isAuthenticated ? (
              <a href="#">
                <button
                  type="button"
                  onClick={
                    logout
                      ? () =>
                          logout({
                            clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
                            // returnTo: window.location.origin,
                          })
                      : undefined
                  }
                >
                  Logout
                </button>
              </a>
            ) : null}
          </section>
        </div>
      </nav>
    </header>
  );
}

const MyButtonLink = (props: { href: string; children: React.ReactNode; title: string; hotkey?: string }) => {
  const [isActive] = useRoute(props.href);
  const [location] = useLocation();
  const router = useRouter();
  const [match] = matchRoute(router.parser, `${props.href}/:p*`, location, true);
  // const myHotkey = props.hotkey || props.title.charAt(0).toLowerCase()
  // const elementRef = useHotkey<HTMLButtonElement>(`g ${myHotkey}`, props.title)
  // console.log(`${myHotkey}`, title)
  return (
    <Link {...props} aria-label={props.title}>
      <a href="#" aria-label={props.title}>
        <button type="button" className={isActive || match ? "hd-outlined" : ""}>
          {props.children}
        </button>
      </a>
    </Link>
  );
};
