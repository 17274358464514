// huWaWi als Single Page Application
// Created in March 2020 by Maximillian Dornseif
// Copyright Cyberlogi, HUDORA 2020-2024

import "./App.css";

import { ConfirmProvider } from "material-ui-confirm";
import React, { Suspense } from "react";
import { Toaster } from "react-hot-toast";
import { Route, Switch } from "wouter";

import { AaaMainChecker } from "./AaaChecker";
// import { BelegeApp } from './Belege/BelegeApp'
import { SearchWidget } from "./SearchWidget";
// import { StammdatenApp } from './Stammdaten/StammdatenApp'
// import { WaWiApp } from './WaWi/WaWiApp'

const DevPageLoader = React.lazy(() => import("./DevPageApp"));
const DocPageLoader = React.lazy(() => import("./Documentation/DocApp"));
const IcPageLoader = React.lazy(() => import("./Ic/IcApp"));
// const ReportingAppLoader = React.lazy(() => import('./Reporting/ReportingApp'))

/** Die eigentliche App */
export function App() {
  return (
    <ConfirmProvider
      defaultOptions={{ title: "Sicher?", confirmationText: "Ok", cancellationText: "Abbrechen" }}
    >
      <SearchWidget />
      <Toaster
        // See https://react-hot-toast.com/docs/toaster
        position="top-center"
        toastOptions={{
          // Default options for specific types
          success: { duration: 5000 },
        }}
      />
      <AaaMainChecker>
        <Switch>
          <Route path="/doc" nest>
            <Suspense fallback={<div>Lade Modul Doc…</div>}>
              <DocPageLoader />
            </Suspense>
          </Route>
          <Route path="/dev/" nest>
            <Suspense fallback={<div>Lade Modul Dev…</div>}>
              <DevPageLoader />
            </Suspense>
          </Route>
          {/* <Route path="/wawi/:p*">
          <WaWiApp />
        </Route> */}
          {/* <Route path="/belege/:p*">
          <BelegeApp />
        </Route> */}
          <Route path="/ic" nest>
            <Suspense fallback={<div>Lade Modul Lager…</div>}>
              <IcPageLoader />
            </Suspense>
          </Route>
          {/* <Route path="/reporting/:p*">
          <Suspense fallback={<div>Lade Modul Reporting…</div>}>
            <ReportingAppLoader />
          </Suspense>
        </Route>
        <Route path="/stamm/:p*">
          <StammdatenApp />
        </Route> */}
          {/* Default route in a switch */}
          <Route>
            <DefaultApp />
          </Route>
        </Switch>
      </AaaMainChecker>
    </ConfirmProvider>
  );
}
function DefaultApp() {
  return <div>Willkommen!</div>;
}
