// huWaWi als Single Page Application
// Created in March 2020 by Maximillian Dornseif
// Copyright Cyberlogi/HUDORA 2020, 2023, 2024

import "@ag-grid-community/styles/ag-theme-balham.css";
import "@ag-grid-community/styles/ag-grid.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./index.css";

import { Auth0Provider } from "@auth0/auth0-react";
import { HdErrorBoundary3 } from "@hudora/hd-error-boundary";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { deDE } from "@mui/material/locale";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import * as Sentry from "@sentry/react";
import { FlagsProvider } from "flagged";
import React from "react";
import { createRoot } from "react-dom/client";
import { QueryParamProvider } from "use-query-params";
import { WindowHistoryAdapter } from "use-query-params/adapters/window";

import { App } from "./App";
import { ApolloApp } from "./lib/App/ApolloApp";
import { AuthenticatingApp } from "./lib/App/AuthenticatingApp";
import { PageFrame } from "./lib/App/PageFrame";
import { ServerVersionChecker } from "./lib/App/VersionChecker";
import { FEATURES } from "./config-features";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_VERSION,
  denyUrls: [/127\.0\.0\.1:3000/i, /localhost:3000/i, "localhost:3000"],
  beforeSend: (event) => {
    if (window.location.hostname === "localhost") {
      console.log(window.location.hostname);
      return null;
    }
    return event;
  },
});
window.addEventListener("unhandledrejection", (err) => {
  Sentry.captureException(err.reason);
});

const onRedirectCallback = (appState: any) => {
  console.log("onRedirectCallback", appState?.returnTo, window.location.pathname, appState);
  // TODO: remove Parameters
  history.replaceState(null, "", appState?.returnTo || window.location.pathname);
};

const theme: any = createTheme(
  {
    components: {
      MuiTextField: {
        defaultProps: {
          variant: "standard",
          margin: "dense",
        },
      },
    },
  },
  deDE,
);

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  // <React.StrictMode>
  <>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <QueryParamProvider adapter={WindowHistoryAdapter}>
          <FlagsProvider features={FEATURES}>
            <Auth0Provider
              domain="hudora.eu.auth0.com"
              clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
              cacheLocation="localstorage"
              onRedirectCallback={onRedirectCallback}
              useRefreshTokens={false}
              authorizationParams={{
                redirectUri: window.location.origin,
                audience: "http://hudora.de/api",
                scope: "openid profile email read",
              }}
            >
              <HdErrorBoundary3>
                <PageFrame>
                  <AuthenticatingApp>
                    <ApolloApp>
                      <ServerVersionChecker />
                      <App />
                    </ApolloApp>
                  </AuthenticatingApp>
                </PageFrame>
              </HdErrorBoundary3>
            </Auth0Provider>
          </FlagsProvider>
        </QueryParamProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </>,
  // {/* </React.StrictMode> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()

// remove serviceWorkers
navigator.serviceWorker.getRegistrations().then(function (registrations) {
  for (const registration of registrations) {
    registration.unregister();
  }
});
